<template>
	<div>
		<v-navigation-drawer v-model="drawer" temporary absolute app>
			<v-list subheader>
				<v-subheader>По рейтингу</v-subheader>
				<v-list-item>
					<section class="">
						<div v-for="i in 5" :key="i" class="mb-2">
							<v-chip 
								class="mr-2" 
								:class="{'purple darken-3':filters.valuation.includes(i), 'grey lighten-4': !filters.valuation.includes(i)}"
								@click="addUniqueToArray(filters.valuation, i)"
							>
								<v-rating length="5" small readonly background-color="orange" color="orange" :value="i"></v-rating>
							</v-chip>
						</div>
					</section>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			<v-list>
				<v-list-item>
					<div class="py-4">
						<v-switch 
							v-model="filters.is_empty" inset class="mt-0 mb-2" hide-details :label="`Без текста`"
						></v-switch>
						<v-switch
							v-model="filters.has_photo" inset class="mt-0 mb-2" hide-details :label="`С фото`"
						></v-switch>
						<v-switch
							v-model="filters.has_video" inset class="mt-0 mb-2" hide-details :label="`С видео`"
						></v-switch>
						<v-switch
							v-model="filters.is_deleted" inset class="mt-0 mb-2" hide-details :label="`Удаленные`"
						></v-switch>
						<v-switch
							v-model="filters.answer" inset class="mt-0 mb-2" hide-details :label="`Без ответа`"
						></v-switch>
					</div>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			<v-list subheader>
				<v-subheader>По продавцам</v-subheader>
				<v-list-item>
					<div>
						<div v-for="account in filter_choices.wbaccount" :key="account.id" class="mb-2">
							<v-chip
								:class="['mr-2', {
									'purple white--text':filters.wbaccount == account.id, 
									'grey darken-4': filters.wbaccount !== account.id && $vuetify.theme.dark,
									'grey lighten-4': filters.wbaccount !== account.id && !$vuetify.theme.dark
								}]"
								@click="filters.wbaccount = filters.wbaccount==account.id ? null : account.id"
							>{{ account.name }}</v-chip>
						</div>
					</div>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<v-sheet>
			<v-btn icon @click="drawer = !drawer">
				<v-icon>mdi-filter-variant</v-icon>
			</v-btn>
		</v-sheet>
		<v-sheet>
			<v-container fluid>
				<v-row>
					<v-col>
						<v-row>
							<v-col>
								<v-menu 
									v-model="datepicker_menu_from" :close-on-content-click="false" 
									:nudge-right="40" transition="scale-transition" offset-y="offset-y" 
									min-width="290px" ref="datepicker_menu_from"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											clearable
											@click:clear="filters.date_after = null"
											v-model="dateFromFormatted"
											label="Начало периода"
											prepend-icon="mdi-calendar"
											readonly="readonly"
											v-bind="attrs"
											hide-details="auto"
											outlined
											dense
											v-on="on">
										</v-text-field>
									</template>
									<v-date-picker
										v-model="filters.date_after"
										:first-day-of-week="1"
										@input="datepicker_menu_from = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col>
								<v-menu 
									v-model="datepicker_menu_to" :close-on-content-click="false" 
									:nudge-right="40" transition="scale-transition" offset-y="offset-y" 
									min-width="290px" ref="datepicker_menu_to"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											clearable
											@click:clear="filters.date_before = null"
											v-model="dateToFormatted"
											label="Конец периода"
											prepend-icon="mdi-calendar"
											readonly="readonly"
											hide-details="auto"
											v-bind="attrs"
											outlined
											dense
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="filters.date_before"
										@input="datepicker_menu_to = false"
										:first-day-of-week="1"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-spacer></v-spacer>
							<v-col>
								<v-text-field
									v-model="filters.text"
									clearable
									outlined
									dense
									@keyup.enter="fetchFeedbacksWithFilters()"
									append-icon="mdi-magnify"
									label="Текст отзыва"
									class="mb-3"
									hide-details="hide-details">
								</v-text-field>
							</v-col>
							<v-col>
								<v-text-field
									v-model="filters.nmid"
									clearable
									outlined
									dense
									@keyup.enter="fetchFeedbacksWithFilters()"
									append-icon="mdi-magnify"
									label="Артикул"
									hide-details="hide-details">
								</v-text-field>
							</v-col>
							<v-col>
								<v-btn
									@click="fetchFeedbacksWithFilters()"
									depressed
									color="purple darken-2"
									class="mb-3"
									dark
								><v-icon dark>mdi-send</v-icon></v-btn>
							</v-col>
							<v-col>
								<v-btn
									@click="exportFeedbacks" depressed
									color="purple darken-2" class="mb-3" dark
									:loading="loading_export"
								>Экспорт</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-sheet>
		<v-skeleton-loader type='table' v-if="initLoading"></v-skeleton-loader>
		<v-data-table
			v-else
			:headers="dataTable.headers"
			:pageCount="pagination.numberOfPages"
			:items="feedbacks"
			item-key="uid"
			@update:page="pageChanged"
			@update:sort-by="sortChanged"
			@update:sort-desc="sortChanged"
			:loading="tableLoading"
			:options.sync="options"
			height="calc(100vh - 250px)"
			:items-per-page="50"
			:fixed-header="true"
			:server-items-length="pagination.totalItems"
			class="elevation-1 vertical-border"
		>
			<template v-slot:item.child_product="{ item }">
				<router-link target="_blank" :to="{ name: 'product_card', params: { id: item.child_product }}">{{ item.child_product }}</router-link>
                <p class="hover_effect pb-0 mb-0">Смотреть на вб</p>
            </template>
			<template v-slot:item.text="{ item }">
				<div><span class="font-weight-bold">Достоинства: </span>{{ item.feedback_pros ? item.feedback_pros : '-' }}</div>
				<div><span class="font-weight-bold">Недостатки: </span>{{ item.feedback_cons ? item.feedback_cons : '-' }}</div>
				<div><span class="font-weight-bold">Комментарий: </span>{{ item.feedback_text ? item.feedback_text : '-' }}</div>
            </template>
			<template v-slot:item.valuation="{ item }">
				<v-rating 
					length="5" small readonly background-color="orange lighten-3" color="orange"
					:value="item.valuation"
                ></v-rating>
            </template>
			<template v-slot:item.is_deleted="{ item }">
				<div v-if="item.is_deleted" class="small-text">{{ item.date_deleted }}</div>
				<v-icon v-if="item.is_deleted" color="red">mdi-checkbox-marked-circle</v-icon>
            </template>
			<template v-slot:item.has_photo="{ item }">
				<v-icon v-if="item.has_photo" color="green">mdi-checkbox-marked-circle</v-icon>
            </template>
			<template v-slot:item.has_video="{ item }">
				<v-icon v-if="item.has_video" color="green">mdi-checkbox-marked-circle</v-icon>
            </template>
			<template v-slot:item.answer="{ item }">
				<v-expansion-panels flat v-if="item.answer">
                    <v-expansion-panel>
						<v-expansion-panel-header>
                            <span>Ответ для {{ item.username }}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div>{{ item.answer }}</div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>
		</v-data-table>
	</div>
</template>

<script>
import {Products} from '@/api/products';
import {General} from '@/api/general';

export default {
	name: 'FeedbacksList',
	data() {
		return {
			loading_export: false,
			initLoading: false,
			tableLoading: false,
			pagination: {
				next: null,
				totalItems: 0,
				numberOfPages: 0,
				currentPage: 0,
			},
			options: {},
			drawer: false,
			filters: {
				wbaccount: null,
				date_before: null,
				date_after: null,
				nmid: null,
				answer: null,
				text: null,
				valuation: [],
				has_photo: null,
				has_video: null,
				is_empty: false,
				is_deleted: null,
			},
			filter_choices: {
				wbaccount: [],
			},
			datepicker_menu_from: null,
			datepicker_menu_to: null,
			feedbacks: [],
			dataTable: {
				headers: [
					{text: 'Артикул', align: 'center', sortable: false, value: 'child_product', width: '9%'},
					{text: 'Название', align: 'center', sortable: false, value: 'name', width: '23%'},
					{text: 'Отзыв', value: 'text', width: '25%', sortable: false},
					{text: 'Дата отзыва', value: 'date_created', width: '6%', align: 'center', sortable: true},
					{text: 'Оценка', value: 'valuation', width: '6%', align: 'center', sortable: true},
					{text: 'Ответ', value: 'answer', width: '25%', align: 'center', sortable: false},
					{text: 'Удален', value: 'is_deleted', width: '7%', align: 'center', sortable: true},
					{text: 'Фото', value: 'has_photo', width: '2%', align: 'center', sortable: true},
					{text: 'Видео', value: 'has_video', width: '2%', align: 'center', sortable: true},
				],
			},
		};
	},
	computed: {
		transfromFiltersForQuery() {
			let query = {};
			for (const f in this.filters) {
				if (Array.isArray(this.filters[f])) {
					query[f] = this.filters[f].join(',');
				} else {
					query[f] = this.filters[f];
				}
			}
			return query;
		},
		dateFromFormatted() {
			return this.filters.date_after ?
				this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) :
				null;
		},
		dateToFormatted() {
			return this.filters.date_before ?
				this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) :
				null;
		},
	},
	methods: {
		setDefaultFromDate() {
			const d = new Date();
			d.setMonth(d.getMonth() - 1);
			this.filters.date_after = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
		},
		addUniqueToArray(arr, value) {
			if (arr.indexOf(value) === -1) {
				arr.push(value);
			} else {
				arr.splice(arr.indexOf(value), 1);
			}
		},
		async sortChanged() {
			await this.getFeedbacks();
		},
		async pageChanged() {
			await this.getFeedbacks();
		},
		async clearFilters() {
			let dirtyFilters = false;
			for (let item in this.filters) {
				if (this.filters[item]) {
					dirtyFilters = true;
				}
				this.filters[item] = Array.isArray(this.filters[item]) ? [] : null;
			}
			if (dirtyFilters) {
				await this.getFeedbacks();
			}
		},
		async fetchWbAccounts() {
			const response = await General.getWbAccounts();
			for (let account in response.data) {
				this.filter_choices.wbaccount.push(response.data[account]);
			}
		},
		async fetchFeedbacksWithFilters() {
			this.drawer = false;
			this.options.page = 1;
			await this.getFeedbacks();
		},
		async getFeedbacks() {
			this.tableLoading = true;
			const {page, itemsPerPage, sortBy, sortDesc} = this.options;
			let pageNumber = page - 1;
			try {
				const query = {
					...this.transfromFiltersForQuery,
					...{
						'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
					},
				};
				const response = await Products.getFeedbacksV2(query)
				this.feedbacks = response.data.results
				this.pagination.totalItems = response.data.count;
				this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage);
			} catch (err) {
				console.log(err.data);
			}
			this.tableLoading = false;
		},
		async exportFeedbacks(){
			this.loading_export = true
			const {sortBy, sortDesc} = this.options;
			try {
				const response = await Products.exportFeedbacksV2({
					...this.transfromFiltersForQuery,
					'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
				})
				const url = URL.createObjectURL(response.data)
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `feedbacks.xlsx`)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			} catch (err) {
				console.log(err.data)
			}
			this.loading_export = false
		}
	},
	async mounted() {
		this.$store.commit('setPageTitle', `Отзывы V2 (с апи)`);
		document.title = `Отзывы V2 (с апи) | mp.lomex-group.ru`;
		this.setDefaultFromDate();
		await this.fetchWbAccounts();
		await this.getFeedbacks();
	},
};
</script>

<style scoped lang="scss">
::v-deep .v-rating .v-icon {
	padding: 0 !important;
}
::v-deep .vertical-border td {
	border-right: 1px solid #eaeaea;;
}
::v-deep tbody {
	tr:hover {
		background-color: transparent !important;
	}
	td {
		.v-expansion-panel {
			margin-top: 5px;
			margin-bottom: 10px;
		}
		.v-expansion-panel-header {
			padding: 5px 10px !important;
			min-height: 0 !important;
			display: flex;
			background: #eee;
			font-size: .8rem;
			&:hover {
					background: #e4e4e4;
			}
		}
		.theme--dark .v-expansion-panel-header{
			background: #121212;
			&:hover{
				background: #343434;
			}
		}
		.v-expansion-panel-content {
			background: #fafafa;
			padding-top: 10px;
			font-size: .8rem;
			line-height: 1rem;
		}
		.theme--dark .v-expansion-panel-content{
			background: #121212;
		}
	}
}
.hover_effect{
	cursor: pointer;
	&:hover{
		text-decoration: underline;
	}
}
</style>
