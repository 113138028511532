<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                Статистика социальных сетей в разрезе менеджеров
                <v-btn class="ml-5" color="primary" :loading="loading_export" @click="export_stats">
                    Экспорт статистики
                </v-btn>
            </v-card-title>
            <v-card-text ref="social_items_filters">
                <div class="d-flex mt-5">
                    <div style="display: flex; flex-direction: column;">
                        <v-menu 
                            v-model="datepicker_menu_create" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_create"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable v-model="dataCreateRangeText" label="Период добавления ролика"
                                    prepend-icon="mdi-calendar" readonly="readonly" 
                                    @click:clear="dates_create_range = []"
                                    v-bind="attrs" hide-details="auto" outlined dense v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dates_create_range" range :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu 
                            v-model="datepicker_menu_stats" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_stats"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable v-model="dataStatsRangeText" label="Период статистики"
                                    prepend-icon="mdi-calendar" readonly="readonly" class="mt-3"
                                    v-bind="attrs" hide-details="auto" outlined dense v-on="on"
                                    @click:clear="dates_stats_range = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dates_stats_range" range :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                    <div style="display: flex; flex-direction: column;" class="ml-3">
                        <v-autocomplete
                            v-model="filters.manager" :items="choices.manager_choices" multiple
                            label="Менеджер" outlined hide-details dense clearable item-text="name" item-value="id"
                        ></v-autocomplete>
                        <v-autocomplete
                            v-model="filters.tutor" :items="choices.tutor_choices" class="mt-3" multiple
                            label="Куратор" outlined hide-details dense clearable item-text="name" item-value="id"
                        ></v-autocomplete>
                    </div>
                    <div style="display: flex; flex-direction: column;" class="ml-3">
                        <v-select
                            v-model="filters.resource" :items="choices.resource_choices" multiple
                            item-text="name" item-value="id" label="Площадка" outlined hide-details dense clearable
                        ></v-select>
                        <v-autocomplete
                            v-model="filters.product_group" :items="choices.products_group_choices" class="mt-3" multiple
                            label="Проект" outlined hide-details dense clearable item-text="name" item-value="id"
                        ></v-autocomplete>
                    </div>
                    <div style="display: flex; flex-direction: column;" class="ml-3">
                        <v-text-field
                            v-model="filters.products_name" label="Название" 
                            outlined clearable dense hide-details
                        ></v-text-field>
                        <v-text-field
                            v-model="filters.products" label="Артикул" 
                            outlined clearable dense class="mt-3" hide-details
                        ></v-text-field>
                    </div>
                    <div>
                        <v-switch
                            v-model="filters.is_smm" inset class="ml-3 mt-0 pt-0" hide-details 
                            :label="`SMM`" v-if="permissions == 'super_manager' || permissions == 'director'"
                        ></v-switch>
                    </div>
                    <v-btn 
                        color="primary" class="ml-5" :loading="loading" @click="list_stats"
                    >Применить фильтры</v-btn>
                </div>
                <v-data-table
                    :headers="headers" :pageCount="pagination.numberOfPages" :items="data"
                    item-key="id" :loading="loading" :options.sync="options"
                    :server-items-length="pagination.totalItems" class="elevation-1 mt-5"
                    :footer-props="{'items-per-page-options': [10, 25]}" group-by="manager_name"
                    hide-default-header
                >
                    <template v-slot:header="{ props, on }">
                        <thead>
                            <tr>
                                <th v-for="header in props.headers" :key="header.text"  @click="on.sort(header.value)">
                                    <div class="text-center" :class="[{'wrap_sorted': header.sortable, 'active_sorted': props.options.sortBy[0] == header.value}]">
                                        {{ header.text }}
                                        <v-icon small v-if="header.sortable && props.options.sortDesc[0]">mdi-arrow-up</v-icon>
                                        <v-icon small v-if="header.sortable && !props.options.sortDesc[0]">mdi-arrow-down</v-icon>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:group.header="{ group, items, headers, toggle, isOpen }">
                        <td :colspan="1" style="text-align: center;">
                            <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                                <v-icon v-else>mdi-chevron-down</v-icon>
                            </v-btn>
                            Менеджер {{ group }}
                        </td>
                        <td :colspan="1" style="text-align: center;">
                            {{ items.reduce((partialSum, a) => partialSum + a.total_views, 0) | toRUBWithoutCurrency}}
                        </td>
                        <td :colspan="1" style="text-align: center;">
                            {{ items.reduce((partialSum, a) => partialSum + a.total_cnt_switching, 0).toFixed(2) }}
                        </td>
                        <td :colspan="1" style="text-align: center;">
                            {{ items.reduce((partialSum, a) => partialSum + a.count_videos, 0) | toRUBWithoutCurrency}}
                        </td>
                        <td :colspan="1" style="text-align: center;">
                            {{ ((items.reduce((partialSum, a) => partialSum + a.total_views, 0)) / (items.reduce((partialSum, a) => partialSum + a.count_videos, 0))).toFixed(2) }}
                        </td>
                        <td :colspan="1" style="text-align: center;">
                            {{ ((items.reduce((partialSum, a) => partialSum + a.total_cnt_switching, 0)) / (items.reduce((partialSum, a) => partialSum + a.total_views, 0)) * 100).toFixed(2) }}
                        </td>
                        <td :colspan="1"></td>
                        <td :colspan="1"></td>
                    </template>
                    <template v-slot:item.resource="{ item }">
                        <div class="d-flex align-center justify-center">
                            {{ item.resource }}
                            <router-link 
                                target="_blank" 
                                :to="{ name: 'social_parser', query: filters_for_page_social_parser([item.manager_id, 0], [item.resource_id, ''])}"
                            ><v-btn icon><v-icon>mdi-link-box-outline</v-icon></v-btn></router-link>
                        </div>
                    </template>
                    <template v-slot:item.graphs_views="{ item }">
                        <div style="max-width: 150px; width: 150px; margin: 0 auto;">
                            <SocialParserCharts :data="item.graphs_views" :name="'Просмотры'" :key="`views_${item.manager_name}_${item.resource}_${counter}`"/>
                        </div>
                    </template>
                    <template v-slot:item.total_cnt_switching="{ item }">
                        {{ item.total_cnt_switching.toFixed(2) }}
                    </template>
                    <template v-slot:item.graphs_cnt_switching="{ item }">
                        <div style="max-width: 150px; width: 150px; margin: 0 auto;">
                            <SocialParserCharts :data="item.graphs_cnt_switching" :name="'Переходы'" :key="`switching_${item.manager_name}_${item.resource}_${counter}`" :with_round="true"/>
                        </div>
                    </template>
                    <template v-slot:item.total_views="{ item }">
                        {{ item.total_views | toRUBWithoutCurrency }}
                    </template>
                    <template v-slot:item.count_videos="{ item }">
                        {{ item.count_videos | toRUBWithoutCurrency }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import { SocialParser } from '@/api/social_parser';
    import SocialParserCharts from '@/views/SocialParser/SocialParserCharts.vue';

	export default {
		name: 'SocialParserBasePage',
        components: {
            SocialParserCharts
        },
		data() {
			return {
                counter: 0,
                permissions: '',
                loading: false,
                loading_export: false,
                headers: [
                    { text: 'Социальная сеть', value: 'resource', width: '2%', sortable: false, align: 'center'},
                    { text: 'Просмотров за период', value: 'total_views', width: '1%', sortable: true, align: 'center'},
                    { text: 'Переходов за период', value: 'total_cnt_switching', width: '1%', sortable: true, align: 'center'},
                    { text: 'Кол-во видео', value: 'count_videos', width: '1%', sortable: true, align: 'center' },
                    { text: 'Просмотры/видео', value: 'relationship_views_count', width: '1%', sortable: true, align: 'center' },
                    { text: 'CR Просмотры/переходы, %', value: 'relationship_views_frq', width: '1%', sortable: true, align: 'center' },
                    { text: 'График просмотров', value: 'graphs_views', width: '3%', sortable: false, align: 'center' },
                    { text: 'График переходов', value: 'graphs_cnt_switching', width: '3%', sortable: false, align: 'center' },
                ],
                data: [],
                options: {
                    sortBy: ['date_created'],
                    sortDesc: [false],
                    itemsPerPage: 25
                },
                pagination: {
                    next: null,
                    totalItems: 0,
                    numberOfPages: 0,
                    currentPage: 0
                },
                filters: {
                    manager: null,
                    tutor: null,
                    resource: null,
                    product_group: null,
                    products_name: null,
                    products: null,
                    is_smm: false
                },
                choices: {
                    manager_choices: [],
                    tutor_choices: [],
                    resource_choices: [],
                    products_group_choices: []
                },
                datepicker_menu_create: false,
                datepicker_menu_stats: false,
                dates_create_range: [],
                dates_stats_range: [],
            }
		},
        watch: {
            'options': async function (newVal, oldVal) {
				if (oldVal) {
					await this.list_stats()
				}
			},
        },
        computed: {
            dataCreateRangeText() {
				return this.dates_create_range.join(' ~ ');
			},
            dataStatsRangeText() {
				return this.dates_stats_range.join(' ~ ');
			},
            transfromFiltersForQuery(){
                let query = {}
                for (const f in this.filters) {
                    if (Array.isArray(this.filters[f])) {
                        query[f] = this.filters[f].join(',')
                    } else {
                        query[f] = this.filters[f]
                    }
                }
                return query
            },
        },
		methods: {
            filters_for_page_social_parser(manager, resource){
                let filters = {manager: manager, resource: resource}
                if (this.dates_create_range)
                    filters['dates_create_range'] = this.dates_create_range
                if (this.dates_stats_range)
                    filters['dates_stats_range'] = this.dates_stats_range
                if (this.filters.products)
                    filters['products'] = this.filters.products
                return filters
            },
            set_base_period(start, end){
                let date = new Date().toISOString().slice(0,10)
                let date_to =  new Date(new Date(date)
                    .setDate(new Date(date)
                    .getDate() - start))
                let date_from =  new Date(new Date(date)
                    .setDate(new Date(date)
                    .getDate() - end))
                this.dates_create_range = [date_from.toISOString().slice(0, 10), date_to.toISOString().slice(0, 10)]
            },
            async fetch_choices() {
                try {
                    const response = await SocialParser.ListManagers()
                    let data = response.data
                    this.choices.manager_choices = data.managers
                    this.choices.products_group_choices = data.products_group
                    this.choices.resource_choices = data.resources
                    this.choices.tutor_choices = data.tutors
                    let current_user = Array.from(this.create.manager_choices)
                                            .find(obj => obj.id == this.$store.getters.userPK)
                    if (current_user)
                        this.filters.manager = current_user.id
                } catch (err) {
                    console.log(err.response)
                }
            },
            async list_stats(){
                this.loading = true
				this.$vuetify.goTo(this.$refs.social_items_filters, {
                    duration: 500, offset: 150, easing: 'linear',
                })
				const { page, itemsPerPage, sortBy, sortDesc } = this.options
				let pageNumber = page - 1
				try {
					let query_data = {
						...this.transfromFiltersForQuery,
						'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage,
						'sort_additional': sortDesc[0] ? sortBy[0]: `-${sortBy[0]}`
					}
                    if (this.dates_create_range.length > 0){
                        if (this.dates_create_range.length == 1)
                            query_data['date_created_after'] = this.dates_create_range[0]
                        else {
                            query_data['date_created_after'] = this.dates_create_range[0]
                            query_data['date_created_before'] = this.dates_create_range[1]
                        }
                        
                    }
                    if (this.dates_stats_range.length > 0){
                        if (this.dates_stats_range.length == 1)
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                        else {
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                            query_data['date_stats_before'] = this.dates_stats_range[1] 
                        }
                    }
                    const response = await SocialParser.socialStatsByManagers(query_data)
					this.data = response.data.results
					this.pagination.totalItems = response.data.count
					this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
                    this.counter += 1
				} catch (err) {
                    this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
					console.log(err.data)
				}
				this.loading = false
            },
            async export_stats(){
                if (this.dates_stats_range.length != 2){
                    this.$toasted.show(`Заполните начальную и конечную дату статистики`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    return
                }
                this.loading_export = true
				try {
                    let query_data = {
						...this.transfromFiltersForQuery,
					}
                    if (this.dates_create_range.length > 0){
                        if (this.dates_create_range.length == 1)
                            query_data['date_created_after'] = this.dates_create_range[0]
                        else {
                            query_data['date_created_after'] = this.dates_create_range[0]
                            query_data['date_created_before'] = this.dates_create_range[1]
                        }
                        
                    }
                    if (this.dates_stats_range.length > 0){
                        if (this.dates_stats_range.length == 1)
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                        else {
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                            query_data['date_stats_before'] = this.dates_stats_range[1] 
                        }
                    }
					const response = await SocialParser.socialStatsByManagersExport(query_data)
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'social_items_manager_stats.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
                    this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
				}
                this.loading_export = false
			},
            async get_permissions(){
                try{
                    const response = await SocialParser.socialPermissions()
                    this.permissions = response.data.permission
                } catch (err){
                    console.log(err)
                }
            },
		},
		async mounted() {
			document.title = 'Статистика социальных сетей по менеджерам';
			this.$store.commit('setPageTitle', `Статистика социальных сетей по менеджерам`)
            await this.get_permissions()
            if (this.permissions == 'smm')
                this.filters.is_smm = true
            await this.fetch_choices()
            this.set_base_period(0, 31)
		},
	};
</script>

<style lang="scss" scoped>
    .wrap_sorted{
		cursor: pointer;
		&:hover{
			color: rgba($color: #000000, $alpha: 0.87);
		}
		i {
			opacity: 0;
		}
		&.active_sorted{
			color: rgba($color: #000000, $alpha: 0.87);
			i{
				opacity: 1;
				color: rgba($color: #000000, $alpha: 0.87);
			}
		}
	}
</style>