<template>
    <v-data-table
        :headers="headers" :items="data" class="elevation-0 mt-5 vertical-border"
        hide-default-footer :loading="loading" dense disable-pagination :item-class="row_classes"
    ></v-data-table>
</template>

<script>
  import { Products } from '@/api/products'
  export default {
    name: "OzonStocksByClusters",
    props: ['product_id'],
    data () {
		return {
			loading: false,
            headers: [
                { text: "Кластер", value: "cluster", sortable: false, width: "1%" },
                { text: "Остаток",  value: "stocks", sortable: true, width: "1%" },
                { text: "Ожид. поставка",  value: "coming", sortable: true, width: "1%" },
                { text: 'Скорость заказов 7 дней', value: 'speed_orders_7', true: false, width: '1%' },
                { text: 'Скорость заказов 14 дней', value: 'speed_orders_14', sortable: true, width: '1%' },
                { text: "Скорость заказов 30 дней", value: "speed_orders_30", sortable: true, width: "1%" },
                { text: "Дней остатка", value: "day_stock", sortable: true, width: "1%" },
                { text: "Срок доставки", value: "avg_delivery", sortable: false, width: "1%" }
            ],
			data: []
		}
    },
    methods: {
		async fetchStocksByClusters(){
			this.loading = true
			try{
				const response = await Products.fetchOzonStocksByCluster(this.product_id)
				this.data = response.data
			} catch(err){
				console.log(err)
			}
			this.loading = false
		},
        row_classes(item) {
            if (item.cluster == 'Итого')
                return this.$vuetify.theme.dark ? 'green darken-4' : 'green lighten-4';
        },
    },
    async mounted () {
		await this.fetchStocksByClusters()
    }
  }
</script>