<template>
    <div>
        <v-dialog v-model="change_item.dialog" max-width="1400" v-if="change_item.dialog">
			<v-card class="py-5 px-5">
                <v-card-title>Изменить налоги на {{change_item.edit_item.date}} год</v-card-title>
				<v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_first_quarter" label="Налог 1 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_second_quarter" label="Налог 2 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_third_quarter" label="Налог 3 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_fourth_quarter" label="Налог 4 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_first_quarter_nds" label="НДС 1 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_second_quarter_nds" label="НДС 2 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_third_quarter_nds" label="НДС 3 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_fourth_quarter_nds" label="НДС 4 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_first_quarter_usn" label="УСН 1 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_second_quarter_usn" label="УСН 2 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_third_quarter_usn" label="УСН 3 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="change_item.new_fourth_quarter_usn" label="УСН 4 квартал, %" outlined
                                type="number" dense clearable hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn 
                        color="primary" dark :loading="change_item.send_loading" @click="updateSellerFax"
                        class="mt-3"
                    >Обновить</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
        <v-container>
            <v-card>
                <v-card-title>Установка налогов</v-card-title>
                <v-card-text>
                    <div class="d-flex mt-5" style="align-items: center;">
                        <h3>Аккаунт: </h3>
                        <div style="max-width:500px;" class="ml-3">
                            <v-autocomplete
                                v-model="current_seller" @change="changeCurrentSeller" :items="sellers"
                                item-text="name" item-value="value" label="Аккаунты продавцов" clearable
                                outlined hide-details dense
                            ></v-autocomplete>
                        </div>
                    </div>
                    <div class="mt-10" v-if="loading">
                        <hr>
                        <v-progress-circular class="mt-3" indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div class="mt-10" v-else-if="general_data">
                        <div class="seller_item mt-3" v-for="(item, index) in general_data" :key="index">
                            <hr>
                            <h3 class="mt-2">
                                Год: {{ item.date }} 
                                <v-icon small class="ml-2" @click="openChangeDialog(item)">mdi-pencil</v-icon>
                            </h3>
                            <v-row class="mt-2">
                                <v-col><h3>Налог, % за 1 квартал: {{ item.first_quarter }} %</h3></v-col>
                                <v-col><h3>НДС, % за 1 квартал: {{ item.first_quarter_nds }} %</h3></v-col>
                                <v-col><h3>УСН, % за 1 квартал: {{ item.first_quarter_usn }} %</h3></v-col>
                            </v-row>
                            <v-row>
                                <v-col><h3>Налог, % за 2 квартал: {{ item.second_quarter }} %</h3></v-col>
                                <v-col><h3>НДС, % за 2 квартал: {{ item.second_quarter_nds }} %</h3></v-col>
                                <v-col><h3>УСН, % за 2 квартал: {{ item.second_quarter_usn }} %</h3></v-col>
                            </v-row>
                            <v-row>
                                <v-col><h3>Налог, % за 3 квартал: {{ item.third_quarter }} %</h3></v-col>
                                <v-col><h3>НДС, % за 3 квартал: {{ item.third_quarter_nds }} %</h3></v-col>
                                <v-col><h3>УСН, % за 3 квартал: {{ item.third_quarter_usn }} %</h3></v-col>
                            </v-row>
                            <v-row>
                                <v-col><h3>Налог, % за 4 квартал: {{ item.fourth_quarter }} %</h3></v-col>
                                <v-col><h3>НДС, % за 4 квартал: {{ item.fourth_quarter_nds }} %</h3></v-col>
                                <v-col><h3>УСН, % за 4 квартал: {{ item.fourth_quarter_usn }} %</h3></v-col>
                            </v-row>                            
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
    
</template>

<script>
    import {General} from '@/api/general'
    import {Catalog} from '@/api/catalog'
    
    export default {
        name: 'CatalogTax',
        data() {
            return{
                sellers: [],
                current_seller: null,
                general_data: [],
                loading: false,
                change_item: {
                    send_loading: false,
                    dialog: false,
                    edit_item: null,
                    new_first_quarter: null,
                    new_first_quarter_nds: null,
                    new_first_quarter_usn: null,
                    new_second_quarter: null,
                    new_second_quarter_nds: null,
                    new_second_quarter_usn: null,
                    new_third_quarter: null,
                    new_third_quarter_nds: null,
                    new_third_quarter_usn: null,
                    new_fourth_quarter: null,
                    new_fourth_quarter_nds: null,
                    new_fourth_quarter_usn: null
                }
            }
        },
        methods:{
            async fetchSeller(){
                try {
                    const responce = await General.fetchSellersChoices()
                    this.sellers = responce.data
                }catch (err) {
                    console.log(err)
                }
            },
            async fetchSellerTax(){
                if (this.current_seller){
                    this.general_data = null
                    this.loading = true 
                    try {
                        const responce = await Catalog.fetchSellerTax({"seller_id": this.current_seller})
                        this.general_data = responce.data
                    }catch (err) {
                        console.log(err)
                    }
                    this.loading = false
                }
            },
            async changeCurrentSeller(){
               await this.fetchSellerTax()
            },
            openChangeDialog(item){
                this.change_item.dialog = true
                this.change_item.edit_item = item
                this.change_item.new_first_quarter = item.first_quarter
                this.change_item.new_first_quarter_nds = item.first_quarter_nds
                this.change_item.new_first_quarter_usn = item.first_quarter_usn
                this.change_item.new_second_quarter = item.second_quarter
                this.change_item.new_second_quarter_nds = item.second_quarter_nds
                this.change_item.new_second_quarter_usn = item.second_quarter_usn
                this.change_item.new_third_quarter = item.third_quarter
                this.change_item.new_third_quarter_nds = item.third_quarter_nds
                this.change_item.new_third_quarter_usn = item.third_quarter_usn
                this.change_item.new_fourth_quarter = item.fourth_quarter
                this.change_item.new_fourth_quarter_nds = item.fourth_quarter_nds
                this.change_item.new_fourth_quarter_usn = item.fourth_quarter_usn
            },
            async updateSellerFax(){
                this.change_item.send_loading = true
                let dict = {
                    'tax_id': this.change_item.edit_item.id,
                    "new_first_quarter": this.change_item.new_first_quarter,
                    "new_first_quarter_nds": this.change_item.new_first_quarter_nds,
                    "new_first_quarter_usn": this.change_item.new_first_quarter_usn,
                    "new_second_quarter": this.change_item.new_second_quarter,
                    "new_second_quarter_nds": this.change_item.new_second_quarter_nds,
                    "new_second_quarter_usn": this.change_item.new_second_quarter_usn,
                    "new_third_quarter": this.change_item.new_third_quarter,
                    "new_third_quarter_nds": this.change_item.new_third_quarter_nds,
                    "new_third_quarter_usn": this.change_item.new_third_quarter_usn,
                    "new_fourth_quarter": this.change_item.new_fourth_quarter,
                    "new_fourth_quarter_nds": this.change_item.new_fourth_quarter_nds,
                    "new_fourth_quarter_usn": this.change_item.new_fourth_quarter_usn,
                }
                try {
                    let response = await Catalog.updateSellerTax(dict)
                    if (response.status == 200){
                        await this.fetchSellerTax() 
                    }
                } catch (err) {
                    console.error(err.response)
                }
                this.change_item.send_loading = false
                this.change_item.dialog = false
                this.change_item.edit_item = null
                this.change_item.new_first_quarter = null
                this.change_item.new_first_quarter_nds = null
                this.change_item.new_first_quarter_usn = null
                this.change_item.new_second_quarter = null
                this.change_item.new_second_quarter_nds = null
                this.change_item.new_second_quarter_usn = null
                this.change_item.new_third_quarter = null
                this.change_item.new_third_quarter_nds = null
                this.change_item.new_third_quarter_usn = null
                this.change_item.new_fourth_quarter = null
                this.change_item.new_fourth_quarter_nds = null
                this.change_item.new_fourth_quarter_usn = null
            }
        },
        async mounted() {
            this.$store.commit('setPageTitle', `Установка налогов | mp.lomex-group.ru`);
            document.title = `Установка налогов | mp.lomex-group.ru`;
            await this.fetchSeller()
        },
    };
</script>

<style scoped>

</style>