<template>
	<div class="px-5 py-5">
		<v-text-field
			v-model="price_without_sale" label="Цена API без скидки" type="number"
			@change="calculate_price_with_sale" outlined clearable dense
		></v-text-field>
		<v-text-field
			v-model="sale" label="Скидка (округление в меньшую сторону)" type="number"
			@change="calculate_price_with_sale" outlined clearable dense
		></v-text-field>
		<v-text-field
			v-model="price_with_sale" label="Цена API со скидкой" type="number"
			@change="calculate_sale" outlined clearable dense
		></v-text-field>
		<div class="d-flex align-center">
			<v-text-field
				v-model="price_with_spp" label="Цена с СПП" type="number"
				outlined readonly dense hide-details
			></v-text-field>
			<p class="ml-5 px-0 py-0 mb-0">СПП: {{ spp_percent ? spp_percent: 0 }}%</p>
		</div>
		<v-text-field
			v-model="vp_plan" label="ВП_бс_н план" type="number"
			outlined readonly dense class="mt-5"
		></v-text-field>
		<v-text-field
			v-model="rentab" label="Рентабельность" type="number"
			outlined readonly dense
		></v-text-field>
		<v-text-field
			v-model="rentab_spp" label="Рентабельность с СПП( РВП от цены с СПП)" type="number"
			outlined readonly dense
		></v-text-field>
		<v-text-field
			v-model="markup" label="Наценка план" type="number" 
			readonly outlined dense
		></v-text-field>
		<div class="d-flex">
			<p class="mb-0">Цена = {{ this.price_with_sale }}</p>
			<p class="mb-0">С/С = {{ this.cost_price }}</p>
			<p class="mb-0">Комиссия = {{ this.comission }}</p>
			<p class="mb-0">Логистика = {{ this.logistic }}</p>
			<p class="mb-0">% выкупа = {{ this.redemption_percent }}</p>
			<p class="mb-0">налог = {{ this.tax }}</p>
			<p class="mb-0">Экваринг = {{ this.acquiring * 100 }}</p>
			<p class="mb-0">об. лог = {{ this.returns_logistic }}</p>
			<p class="mb-0">Расходы склада = {{ this.planned_warehouse_expenses }}</p>
		</div>
		<div class="d-flex mt-5 justify-space-between">
			<v-btn @click="savePriceAPI(false)" color="primary">Обновить цену и скидку вб</v-btn>
			<v-btn @click="savePriceAPI(true)">Сохранить у нас</v-btn>
		</div>
	</div>
</template>

<script>
	import {Products} from '@/api/products';
	export default {
		name: 'ChangePriceApi',
		data(){
			return {
				price_without_sale: 0,
				sale: 0,
				price_with_sale: 0
			}
			
		},
		props: {
			product_id: { type: String },
			current_price_without_sale: { type: Number, required: false, default: 0 },
			current_sale: { type: Number, required: false, default: 0 },
			comission: { type: Number, required: false, default: 0 },
			logistic: { type: Number, required: false, default: 0 },
			redemption_percent: { type: Number, required: false, default: 0 },
			cost_price: { type: Number, required: false, default: 0 },
			tax: { type: Number, required: false, default: 0 },
			acquiring: { type: Number, required: false, default: 0 },
			spp_percent: { type: Number, required: false, default: 0 },
			returns_logistic: { type: Number, required: false, default: 0},
			planned_warehouse_expenses: { type: Number, required: false, default: 0}
		},
		computed: {
			vp_plan(){
				let price = this.price_with_sale ? this.price_with_sale : 0
				let comission = this.comission ? this.comission : 0
				let logistic = this.logistic ? this.logistic : 0
				let redemption_percent = this.redemption_percent ? this.redemption_percent : 0
				let cost_price = this.cost_price ? this.cost_price : 0
				let tax = this.tax ? this.tax : 0
				let acquiring = this.acquiring ? this.acquiring : 0
				let returns_logistic = this.returns_logistic ? this.returns_logistic : 0
				let planned_warehouse_expenses = this.planned_warehouse_expenses ? this.planned_warehouse_expenses : 0
				return (
					(price - price * comission / 100) * redemption_percent / 100 - 
					cost_price * redemption_percent / 100 - 
					logistic - returns_logistic * (1 - redemption_percent / 100) - 
					price * tax * redemption_percent / 10000 - 
					price * acquiring - planned_warehouse_expenses * redemption_percent / 100
				)
			},
			rentab(){
				return this.price_with_sale != 0 ? (this.vp_plan / this.price_with_sale * 100).toFixed(2): 0
			},
			rentab_spp(){
				return this.price_with_spp != 0 ? (this.vp_plan / this.price_with_spp * 100).toFixed(2): 0
			},
			markup(){
				return this.cost_price && this.cost_price != 0 ? (this.vp_plan / this.cost_price * 100).toFixed(2): 0
			},
			price_with_spp(){
				let price = this.price_with_sale ? this.price_with_sale : 0
				let spp = this.spp_percent ? this.spp_percent : 0
				return (price - price * spp / 100).toFixed(2)
			}
		},
		methods: {
			calculate_price_with_sale(){
				this.price_with_sale = (this.price_without_sale - this.price_without_sale * this.sale / 100).toFixed(2)
			},
			calculate_sale(){
				this.sale = ((this.price_without_sale - this.price_with_sale) * 100 / this.price_without_sale).toFixed(2)
			},
			async savePriceAPI(flag){
				try{
					const response = await Products.savePriceAPI(this.product_id, {
						"price_api": this.price_without_sale,
						"discount_api": this.sale,
						"flag_local": flag
					})
					if (response.status == 200) {
						this.$toasted.show(`Цена и скидка изменена`, {
							theme: 'bubble',
							type: 'success',
							position: 'top-center',
							duration: 3000,
						});
					}
				} catch (err) {
					this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
				}
				this.$emit('finishUpdatePrice')
			},
		},
		async mounted() {
			this.price_without_sale = this.current_price_without_sale
			this.sale = this.current_sale
			this.calculate_price_with_sale()
		},
	};
</script>

<style scoped>

</style>
