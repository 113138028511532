<template>
    <v-card rounded :loading="loading" class="mt-5">
        <v-card-title>Последние задачи за 3 дня</v-card-title>
        <v-card-text class="pa-0">
            <v-skeleton-loader v-if="loading" type="list-item-two-line@3"></v-skeleton-loader>
            <task-history-list v-else :data="data" :is_max_width="true"></task-history-list>
        </v-card-text>
    </v-card>
</template>

<script>
    import { SocialParser } from '@/api/social_parser';
    import TaskHistoryList from '@/components/Dashboard/TaskHistoryList';

	export default {
		name: 'SocialParserError',
		data() {
			return {
                loading: false,
                data: []
            }
		},
        components: {TaskHistoryList},
		methods: {
            open_dialog_delete(item){
                this.deleted.dialog = true
                this.deleted.deleted_item = item
            },           
            async fetch_error_list(){
                this.loading = true
				try {
                    const response = await SocialParser.ListErrors()
					this.data = response.data
				} catch (err) {
					console.log(err.data)
				}
                this.loading = false
            },
		},
		async mounted() {
			document.title = 'Ошибки социальных сетей';
			this.$store.commit('setPageTitle', `Ошибки социальных сетей`)
            await this.fetch_error_list()
		},
	};
</script>

<style lang="scss">
</style>