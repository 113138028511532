<template>
    <v-container fluid grey lighten-4 class="pa-5">
        <h2 class="d-flex">
            {{table.general_data.name}}
            <v-switch 
                color="primary" class="ml-5 mt-0" hide-details :label="`Автообновление`" dense
                v-model="table.general_data.is_auto_update" @change="change_auto_update()"
            ></v-switch>
            <v-switch 
                color="primary" class="ml-5 mt-0" hide-details :label="`Открыт для всех`" dense
                v-model="table.general_data.is_open" @change="change_is_open()"
            ></v-switch>
        </h2>
        <v-data-table
            :headers="table.headers" :items="table.data"
            :loading="table.loading" class="elevation-0 small-font mt-3 vertical-border"
            hide-default-footer fixed-header height="calc(100vh - 220px)"
            disable-pagination dense
        >
            <template v-slot:item.img="{ item }">
                <div class="image">
                    <img style="max-height:100px" :src="item.primary_image" alt="">
                    <img class="big_img" :src="item.primary_image" alt="">
                </div>
            </template>
            <template v-slot:item.name="{ item }">
                <div style="width: 300px;">{{ item.name }}</div>
            </template>
            <template v-slot:item.fbo_sku="{ item }">
                <div style="white-space: nowrap;">
                    <router-link 
                        target="_blank" :to="{ name: 'ozon_product_card', params: { id: item.mid }}"
                    ><span class="grey--text">OZON ID: </span>{{ item.mid }}</router-link>
                    <div><span class="grey--text">FBO SKU: </span>{{ item.fbo_sku }}</div>
                    <div><span class="grey--text">FBS SKU: </span>{{ item.fbs_sku }}</div>
                </div>
            </template>
            <template v-slot:item.stocks_api="{ item }">
                <div style="white-space: nowrap;">
                    <div>{{ item.stocks_api }}</div>
                    <hr>
                    <div>{{ item.stocks_1c }}</div>
                </div>
            </template>
            <template v-slot:item.speed_orders_14="{ item }">
                <div style="white-space: nowrap;">
                    <div>{{ item.speed_orders_14 }}</div>
                    <hr>
                    <div>{{ item.speed_orders_7 }}</div>
                </div>
            </template>
            <template v-slot:item.stock_day_mp="{ item }">
                <div style="white-space: nowrap;">
                    <div>{{ item.stock_day_mp }}</div>
                    <hr>
                    <div>{{ item.stock_day_mp_1c }}</div>
                </div>
            </template>
            <template v-slot:item.turnover_14="{ item }">
                <div style="white-space: nowrap;">
                    <div>{{ item.turnover_14 }}</div>
                    <hr>
                    <div>{{ item.turnover_with_1c_14 }}</div>
                </div>
            </template>
            <template v-slot:item.action="{ item }">
				<v-icon small @click="refresh_single_data(item)">
					mdi-reload
				</v-icon>
			</template>
            <template 
				v-for="prop in ['price_api_cost', 'cost_price', 'commission', 'logistic', 'last_mile', 'tax']"
				v-slot:[`item.${prop}`]="{ item }"
			>
				<div :key="`field_${prop}`">
					<div style="min-width: 75px; width: 100%; height: 100%; background-color: inherit;" >
						<div 
                            class="test" v-if="`editing_field_${prop}_${item.mid}` != editing_input" 
                            @dblclick="editing_input = `editing_field_${prop}_${item.mid}`"
                        >{{ item[prop] ? Number(item[prop]).toLocaleString(): 0}}</div>
						<input 
							class="test" style="border: 1px solid black;" v-else type="number" 
							:value="item[prop]" @change="change_input_data(item, prop, $event)"
                        >
                        <v-icon 
                            x-small class="ml-2" v-if="prop == 'price_api_cost'" @click="open_change_price_dialog(item)"
                        >mdi-pencil</v-icon>
					</div>
				</div>
			</template>
        </v-data-table>
        <div class="mt-5" style="width: 100%; display: flex; justify-content: space-between;">
            <div>
                <div v-if="!table.general_data.url_export_table">
                    <v-text-field 
                        v-model="export_google.email" clearable outlined dense hide-details
                        label="Введите email перед созданием таблицы" v-if="table.general_data.is_owner"
                    ></v-text-field>
                    <div class="mt-3" v-if="!table.general_data.url_export_table && table.general_data.name">
                        <v-btn 
                            depressed color="blue darken-2" style="font-size: .9em;" :loading="export_google.loading"
                            dark @click="export_google_sheet(false)" v-if="table.general_data.is_owner"
                        >Сформировать google sheet</v-btn>
                        <v-alert density="compact" type="warning" v-else>
                            У вас нет прав для создания google таблицы, обратитесь к владельцу таблицы
                        </v-alert>
                    </div>
                </div>
                <div v-else>
                    <div class="mt-3" v-if="table.general_data.url_export_table">
                        <v-btn depressed color="orange darken-2" style="font-size: .9em;" dark>
                            <a :href="table.general_data.url_export_table" target="_blank">Открыть google sheet</a>
                        </v-btn>
                    </div>
                    <div class="mt-3" v-if="table.general_data.url_export_table">
                        <v-btn 
                            depressed color="green darken-2" style="font-size: .9em;" :loading="export_google.loading"
                            dark @click="export_google_sheet(false)"
                        >Обновить данные в google sheet</v-btn>
                    </div>
                    <div class="mt-3" v-if="table.general_data.url_export_table && table.general_data.is_owner">
                        <v-btn 
                            depressed color="red darken-2" style="font-size: .9em;" :loading="export_google.loading"
                            dark @click="export_google_sheet(true)"
                        >Удалить google sheet</v-btn>
                    </div>
                </div>
			</div>
            <div class="d-flex">
				<div>
					<v-btn 
						depressed color="blue darken-2" style="font-size: .9em;"
						dark @click="clear_template_state"
					>Обновить данные</v-btn>
				</div>
                <div>
					<v-btn 
                        depressed color="blue darken-2" style="font-size: .9em;" dark class="ml-5"
                        @click="$router.push({name: 'ozon_management_product_change', params: {id: template_id}})"
                    >Изменить шаблон</v-btn>
				</div>
			</div>			
        </div>  
        <v-dialog v-model="change_price_func.dialog" max-width="600" v-if="change_price_func.dialog" scrollable>
            <v-card>
                <v-card-title class="text-h5">Изменение цены</v-card-title>
                <OzonChangePrice
                    :product_id="change_price_func.editing_item.mid"
                    :current_min_price="change_price_func.editing_item.min_price"
                    :current_price="change_price_func.editing_item.price"
                    :current_old_price="change_price_func.editing_item.old_price"
                    :comission="change_price_func.editing_item.commission"
                    :logistic="change_price_func.editing_item.logistic"
                    :last_mile="change_price_func.editing_item.last_mile"
                    :cost_price="change_price_func.editing_item.cost_price"
                    :tax="change_price_func.editing_item.tax"
                />
            </v-card>
        </v-dialog>
    </v-container>
</template>
  
<script>
	import { ManagementProduct } from '@/api/management_product'
    import OzonChangePrice from '@/components/Products/OzonChangePrice'
    
	export default {
		name: 'ManagementOzonProductDetail',
        components: { OzonChangePrice },
		data() {
			return {
                table: {
                    loading: false,
                    general_data: {},
                    data: [],
                    fresh_data: [],
                    headers: [
                        { text: 'Изображение', sortable: false, value: 'img', width: '1%' },
                        { text: 'Название', sortable: true, value: 'name', width: '20%' },
                        { text: 'Бренд', sortable: true, value: 'catalog_item.brand', width: '1%' },
                        { text: 'Статус', sortable: true, value: 'catalog_item.status_name', width: '1%' },
                        { text: 'Группа товаров', sortable: true, value: 'catalog_item.code1C_tag_group_name', width: '1%' },
                        { text: 'ЛК', sortable: true, value: 'catalog_item.account_name', width: '1%' },
                        { text: 'Артикул МП', sortable: true, value: 'fbo_sku', width: '1%' },
                        { text: 'Артикул 1с', sortable: true, value: 'catalog_item.code1C_code', width: '1%' },
                        { text: 'Цена', sortable: true, value: 'price_api_cost', width: '1%' },
                        { text: 'СПП', sortable: true, value: 'spp_ozon', width: '1%' },
                        { text: 'с/с', sortable: true, value: 'cost_price', width: '1%' },
                        { text: 'Комиссия', sortable: true, value: 'commission', width: '1%' },
                        { text: 'Логистика', sortable: true, value: 'logistic', width: '1%' },
                        { text: 'Последняя миля', sortable: true, value: 'last_mile', width: '1%' },
                        { text: 'Налог', sortable: true, value: 'tax', width: '1%' },
                        { text: 'ВП', sortable: true, value: 'vp', width: '1%' },
                        { text: 'Наценка', sortable: true, value: 'markup', width: '1%' },
                        { text: 'Рентабельность', value: 'rentab', sortable: true, width: '1%' },
                        { text: 'Остатки апи / 1с', sortable: true, value: 'stocks_api', width: '1%' },
                        { text: 'Скорость заказов 14 / 7', sortable: true, value: 'speed_orders_14', width: '1%' },
                        { text: 'Дней остаток мп / мп + 1с', sortable: true, value: 'stock_day_mp', width: '1%' },
                        { text: 'Обновить', sortable: false, value: 'action', width: '1%' }
                    ]
                },
                export_google: {
                    loading: false,
                    email: null
                },
                filters: {
                    mid: null,
                    code_1c: null
                },
                editing_input: null,
                change_price_func: {
                    dialog: false,
                    editing_item: null
                }
			}
		},
		computed: {
			template_id(){
				return this.$route.params.template_id;
			},
		},
        watch: {
			'table.data': async function (newVal, oldVal) {
				if (oldVal != newVal) {
					await this.save_template_state()
				}
			}
		},
		methods: {	
            async change_auto_update(){
				try{
					await ManagementProduct.autoUpdateOzonTemplates(this.template_id)
				} catch(err){
					console.log(err)
				}
			},
            async change_is_open(){
				try{
					await ManagementProduct.changeOzonIsOpen(this.template_id)
				} catch(err){
					console.log(err)
				}
			},
            open_change_price_dialog(item){
                this.change_price_func.dialog = true
                this.change_price_func.editing_item = item
            },
            change_input_data(item, prop, event){
                let index = this.table.data.indexOf(item)
                this.table.data[index][prop] = event.target.value
                this.editing_input = null
                this.calculation_data()                
            },
            refresh_single_data(item){
				let index = this.table.data.findIndex((el) => el.mid == item.mid)
				let index2 = this.table.fresh_data.findIndex((el) => el.mid == item.mid)
				this.table.data[index] = this.table.fresh_data[index2]
                this.calculation_data()
			},
            async clear_template_state(){
				try{
					await ManagementProduct.clearStateOzonTemplates(this.template_id)
					await this.fetch_data()
				} catch (err){
					console.log(err)
				}
			},
            async save_template_state (){
				let json_data = JSON.stringify(this.table.data)
				try{
					await ManagementProduct.saveStateOzonTemplates(this.template_id, {"state": json_data})
                } catch (err){
					console.log(err)
				}
			},
			async fetch_data(){
				this.table.loading = true
				try {
					const response = await ManagementProduct.fetchOzonDetailTemplates(this.template_id)
					this.table.data = response.data.saved_data && response.data.saved_data.length > 0 ? response.data.saved_data : response.data.data
					this.table.general_data = response.data.general_data
                    this.table.fresh_data = response.data.data && response.data.data.length > 0 ? response.data.data : []
                    this.calculation_data()
				} catch (err) {
					console.log(err)
				}
				this.table.loading = false
			},
			async export_google_sheet(is_reset){
				this.export_google.loading = true
				let json_data = {"email": this.export_google.email}
				if (is_reset)
					json_data['is_reset'] = true
				try {
                    const response = await ManagementProduct.exportOzonTemplates(this.template_id, json_data)
					this.table.general_data.url_export_table = response.data.url_export_table
                    this.$toasted.show('Успешно', {
                      theme: 'bubble', type: 'success', position: 'top-center', duration: 7000,
                    });    
                } catch(err){
                    this.$toasted.show(`Ошибка: ${err.response.data.error}`, {
                      theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                }
				this.export_google.loading = false
			},
            calculation_data(){
				let result = []
				this.table.data.forEach(item=>{
                    let price = item.price_api_cost
                    let vp = (
                        price - item.cost_price - price * item.commission / 100 - 
                        item.logistic - item.last_mile - item.tax * price / 100 - 
                        price * 0.01
                    )
					let rentab = vp != 0 && price != 0 ? vp / price * 100 : 0
					let markup = vp != 0 && item.cost_price != 0 ? vp / item.cost_price * 100 : 0
					item.vp = vp.toFixed(2)
                    item.rentab = rentab.toFixed(2)
                    item.markup = markup.toFixed(2)
					result.push(item)
				})
				this.table.data = result
			},
		},
		async mounted(){
			document.title = `Управление ценой ОЗОН (${this.template_id})`;
			this.$store.commit('setPageTitle', `Управление ценой ОЗОН (${this.template_id})`)
			await this.fetch_data()
			document.title = `Управление ценой (${this.table.general_data.name})`;
			this.$store.commit('setPageTitle', `Управление ценой (${this.table.general_data.name})`)
		}
	}
</script>

<style lang="scss" scoped>
	.image{
		height: 50px;
		width: 100%;
		margin: 5px auto 0 auto;
		margin-top: 5px;
		img{
			max-width: 100%;
			height: 50px;
			object-fit: cover;
		}
		.big_img{
			display: none;
			position: absolute;
			top: calc(50vh - 200px);
			left: calc(50% - 100px);
			right: 0;
			bottom: 0;
			z-index: 20;
			width: 300px;
			height: auto;
		}
		&:hover{
			.big_img{
				display: block;
			}
		}
	}
    .test{
		width: 100%;
		height: 100%;
		cursor: pointer;
		font-style: italic;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.test::-webkit-outer-spin-button,
	.test::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
</style>