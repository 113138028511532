<template>
    <v-list height="600px" class="scroll" dense>
        <v-list-item two-line v-for="item in data" :key="item.id">
            <v-list-item-icon>
                <v-icon
                        v-if="item.success"
                        color="green lighten-1"
                >
                    mdi-check-circle
                </v-icon>

                <v-icon
                        v-else
                        color="red darken-3"
                >
                    mdi-close-circle
                </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-text="item.type"></v-list-item-title>
                <v-list-item-subtitle> {{ item.date_created | formatDateToLocale }}</v-list-item-subtitle>
                <v-list-item-subtitle> {{ item.notes }}</v-list-item-subtitle>
                <v-dialog
                        v-model="dialog"
                        scrollable
                        :max-width="`${is_max_width ? 1600 : 700}px`"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item-subtitle v-if="item.error_notes" v-bind="attrs" v-on="on">
                            <a href="#" class="red--text text--accent-2">Показать ошибки</a>
                        </v-list-item-subtitle>
                    </template>
                    <v-card>
                        <v-card-text style="height: 500px;" class="pa-10">
                            <pre>{{ item.error_notes }}</pre>
                        </v-card-text>
                    </v-card>
                </v-dialog>

            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
	export default {
		name: 'TaskHistoryList',
        props: {
			data: { type: Array },
			is_max_width: { type: Boolean, required: false, default: false },
		},
	};
</script>

<style scoped>

</style>